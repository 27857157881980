import { API_URL, http } from "./http.service";

export const getAirTimes=()=>{
    try {
        return http.get(API_URL+'transactions/airtime');
    } catch (error) {
        return error;
    }
}

export const addNewAirTimes=(payload)=>{
    try {
        return http.post(API_URL+'transactions/airtime',payload);
    } catch (error) {
        return error;
    }
}