

// export const print = (data) => {
//     var mywindow = window.open('', '', 'height=600,width=800');
//     mywindow.document.write('<html><head><title>Print</title>');
//     mywindow.document.write('</head><body >');
//     mywindow.document.write(data);
//     mywindow.document.write('</body></html>');
//     mywindow.print();
//     mywindow.close();
// }
export const print = (content) => {
    const mywindow = window.open('', '', 'height=600,width=800');
    mywindow.document.write('<html><head><title>Print</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(content);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); 
    mywindow.focus(); 

    mywindow.onload = () => {
        mywindow.print();
        setTimeout(() => {
            mywindow.close();
        }, 1000);
    };
};
