import React, { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import AdminHeader from "../../components/admin/AdminHeader";
import Wrapper from "../../components/common/Wrapper";
import CashPower from "./CashPower";
import AirPower from "./AirPower";
import PostPaid from "./PostPaid";
import AdminUser from "./User";
import CashPowerCharges from "./CashPowerCharges";
import CreditAgents from "./CreditAgents";
import RevenueReport from "./RevenueReport";
import Backup from "./Backup";
import Cleanup from "./Cleanup";
import { Navigate, useNavigate } from 'react-router-dom';
import { isLogin } from "../../services/auth.service";
import CustomerEnquiries from "./CustomerEnquiries";
import ChangePassword from "./ChangePassword";
import ShiftEnquiries from "./ShiftEnquiries";
import Balance from "../Cashier/Balance";

const Admin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const toggle = () => {
    setIsOpen(!isOpen);
  }
  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  }

  useEffect(() => {
    if (!isLogin()) {
      navigate('/login');
    }
  }, [])
  return (
    <div className="admin container-fluid m-0">
      <div className="row">

        <div className="d-none d-sm-block d-md-none position-absolute top-0 start-0">
          {
            isOpen ? <Sidebar isOpen={isOpen} toggle={toggle} /> : ''
          }
        </div>
        <div className="admin-side toggle-width d-none d-md-block d-lg-block col-md-2 p-0 maindivsidebar" >

          <Sidebar />
        </div>
        <div className="col-md-10 main-data" style={{ background: "#F4F7FE" }}>
          <Wrapper>
            <AdminHeader toggle={toggle} isOpen={isOpen} />
            <Routes>
              <Route path="" index element={<Navigate to="dashboard" />}></Route>
              <Route path="dashboard" index element={<Dashboard />}></Route>
              <Route path="cash-power" index element={<CashPower />}></Route>
              <Route path="air-power" index element={<AirPower />}></Route>
              <Route path="post-paid" index element={<PostPaid />}></Route>
              <Route path="user" index element={<AdminUser />}></Route>
              <Route path="credit-agents" index element={<CreditAgents />}></Route>
              <Route path="cash-power-charges" index element={<CashPowerCharges />}></Route>
              <Route path="report" index element={<RevenueReport />}></Route>
              <Route path="backup" index element={<Backup />}></Route>
              <Route path="cleanup" index element={<Cleanup />}></Route>
 

              <Route path="customer-enquiries" index element={<CustomerEnquiries />}></Route>
              <Route path="shift-enquiries" index element={<ShiftEnquiries />}></Route>
              <Route path="change-password" index element={<ChangePassword />}></Route>

              <Route path="balance" index element={<Balance />}></Route>


            </Routes>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

export default Admin;
