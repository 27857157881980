// import axios from "axios";

// export const API_URL= 'https://api.testwpwebsite.online/api/';

// const secret= 'YIi4AxSZmWcYNjoMytH7XaUa8';

// let token = localStorage.getItem('token');

// axios.defaults.headers['client-access-token']= secret;
// axios.defaults.headers['x-auth-token']= token;

// export const http= axios;

import axios from "axios";

export const API_URL = 'https://api.testwpwebsite.online/api/';
const secret = 'YIi4AxSZmWcYNjoMytH7XaUa8';

let token = localStorage.getItem('token');
axios.defaults.headers.common['client-access-token'] = secret;
axios.defaults.headers.common['x-auth-token'] = token;

export const http = axios;