import React from 'react'
import HeroSection from '../components/common/HeroSection'
import WhyChooseUs from '../components/common/WhyChooseUs'
import OurTeam from '../components/common/OurTeam'
import ContactUs from '../components/common/ContactUs'
import Footer from '../components/common/Footer'

const Home = () => {
  return (
    <div>
        <HeroSection />

        <section className='col-md-8 col-sm-12 mx-auto text-center my-5 container-fluid'>
            <h1 className='mb-4'>
                About Flex Power
            </h1>
            <p className='fw-medium'>
            Flex Power is your one stop shop  for all your electricity needs. We offered two platforms for selling  cashpower. We sell to our retail customers through flexpower.com in our  headquarters office located in Sinchu Alhagi and we also sell wholesale  to our sub vendors throughout the country. If you are interested in  partnering with us as sub vendor, please don’t hesitate to contact us  either by phone, or email.  
            </p>
            <p className='fw-medium mt-3'>
            In addition to flexpower.com, we  also retail cashpower through flexpower.com for people living in the  diaspora. Don’t let your family stay in the dark! Simply use your debit  or credit card to purchase cashpower for your people back home!
            </p>
        </section>

        <WhyChooseUs />
        <OurTeam />

        <ContactUs />
        <Footer />
    </div>
  )
}

export default Home