import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { onlyNumber } from "../../helpers/onlyNumber";
import { getCookie } from "../../helpers/getCookie";
import { addNewAirTimes } from "../../services/air-power.service";
import {toast} from 'react-toastify';

const AddAirPower = ({ show, handleClose }) => {
  const [form, setForm] = useState({
    amount: "",
    user_id: "",
    user_location: "NC",
    vend_amount: 0,
    vendor_type: "Africell",
    mobile_number:""
  });
  let userId = getCookie("user_id");
  let location = getCookie("location");

  useEffect(() => {
    setForm({
      ...form,
      user_id: userId,
      user_location: location
    });
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const submit = async (e) => {
    try {
      let vend_amount = 0;
      let commission = 0.04;
      if (form.amount >= 1) {
            vend_amount = form.amount - form.amount * commission;
      }
      const result = await addNewAirTimes({...form,vend_amount});
      console.log(result);
      if (result.status == 200 && result.data?.data) {
        setForm({
          amount: "",
          user_location: location,
          vend_amount: 0,
          vendor_type: "",
          user_id: userId,
          mobile_number:""
        });
        handleClose();
      }else{
        toast.error(result.data?.error)
      }
    } catch (error) {}
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-indigo"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            name="mobile_number"
            value={form.mobile_number}
            onChange={handleChange}
            type="text"
            onKeyPress={onlyNumber}
            maxLength={15}
            placeholder="Mobile Number"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            name="amount"
            value={form.amount}
            onChange={handleChange}
            type="text"
            placeholder="Amount"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Vendor</Form.Label>
          <Form.Select
            className="mb-5"
            value={form.vendor_type}
            name="vendor_type"
            onChange={handleChange}
            aria-label="Vendor Type"
          >
            <option value="Comium">Comium</option>
            <option value="Africell">Africell</option>
            <option value="Qcell">Qcell</option>
            <option value="Gamcell">Gamcell</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" className="px-4" onClick={submit}>
          OK
        </Button>
        <Button variant="danger" className="px-4" onClick={handleClose}>
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAirPower;
