import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTables from "../../components/common/DataTables";
import AddUserForm from "../../components/forms/AddUserForm";
import { deleteUserById, getUsers } from "../../services/users.service";
import EditDeleteIcon from "../../components/common/EditDeleteIcon";
import CsvDownloadButton from "react-json-to-csv";
import { filterArrayByChar } from "../../helpers/filterArrayByChar";
import ConfirmModal from "../../components/common/ConfirmModal";

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleShow = () => {
    setShow(true);
    setSelectedUser(null);
  };

  let timeout;
  useEffect(() => {
    getUsersData();
  }, []);

  useEffect(() => {
    timeout = setTimeout(() => {
      filterSearch();
    }, 400);
    return () => clearTimeout(timeout);
  }, [search]);

  const filterSearch = () => {
    let records = [...tempUsers];
    if (records?.length) {
      if (search?.trim()) {
        let items = filterArrayByChar(records, search);
        setUsers(items);
      } else {
        setUsers(records);
      }
    }
  };
  const getUsersData = async () => {
    try {
      setLoading(true);
      const result = await getUsers();
      if (result.status === 200) {
        setUsers(result?.data || []);
        setTempUsers(result?.data || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch users.");
    }
  };

  const editRow = (row) => {
    setSelectedUser(row);
    setShow(true);
  };

  const deleteRow = (row) => {
    setSelectedUser(row);
    setDeleteShow(true);
  };

  const deleteUser = async () => {
    try {
      let index = tempUsers.findIndex((u) => u?.id === selectedUser?.id);
      let updatedUsers = [...tempUsers];
      updatedUsers.splice(index, 1);
      setUsers(updatedUsers);
      setTempUsers(updatedUsers);
      let result = await deleteUserById(selectedUser?.id);
      if (result?.status === 200) {
        toast.success("User deleted successfully.");
        setSelectedUser(null);
      }
    } catch (error) {
      toast.error("Failed to delete user.");
    }
    setDeleteShow(false);
  };

  const updateUserList = (user) => {
    if (selectedUser) {
      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? user : u))
      );
      setTempUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? user : u))
      );
    } else {
      setUsers((prevUsers) => [...prevUsers, user]);
      setTempUsers((prevUsers) => [...prevUsers, user]);
    }
  };

  const columns = [
    {
      name: "Action",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      cell: (row) => <EditDeleteIcon row={row} deleteRow={deleteRow} edit={editRow} />,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      reorder: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
      reorder: true,
    },
    {
      name: "Cash Power Balance",
      selector: (row) => row.nawec_balance,
      sortable: true,
      reorder: true,
    },
  ];

  return (
    <div className="py-3">
      <div className="row my-4">
        <div className="col-md-6 col-sm-12 mb-2">
          <div className="p-4 d-flex justify-content-between bg-white br-20">
            <h4 className="text-indigo">Add a new user</h4>
            <span className="bg-indigo px-5 py-2 text-white cursor-pointer" onClick={handleShow}>Click Here</span>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 mb-2">
          <div className="p-4 bg-white br-20">
            <h4 className="text-indigo">All Users</h4>
          </div>
        </div>
      </div>
      <div className="br-20 bg-white p-4">
        <div className="d-flex justify-content-between">
          <h3 className="text-light-blue">
            All System Users
          </h3>
          <div className="search-input">
            <div className="bg-white admin-search p-2 input-group br-20">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? <span onClick={() => setSearch('')} className="position-absolute fs-5 cursor-pointer z-10" style={{ top: 13, right: 60 }}>
                <i className="bi bi-x"></i>
              </span> : ''}
              <div className="ms-2 mt-1">
                <CsvDownloadButton
                  filename="users"
                  data={users}
                  style={{ background: "transparent", border: "none" }}
                >
                  <svg
                    className="cursor-pointer"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4256 15.5107 17.4256H2.44767Z"
                      fill="#23AEE6"
                    />
                  </svg>
                </CsvDownloadButton>
              </div>
            </div>
          </div>
        </div>

        <DataTables
          columns={columns}
          data={users}
          pagination
          progressPending={loading}
        />
      </div>

      <AddUserForm
        show={show}
        handleClose={handleClose}
        selectedUser={selectedUser}
        getUsersData={getUsersData}
        updateUserList={updateUserList}
      />
      <ConfirmModal heading="Delete User"
        message="Are you sure want to delete?"
        show={deleteShow} onHide={handleDeleteClose} onSubmit={deleteUser} />

    </div>
  );
};

export default AdminUser;
