import { API_URL, http } from "./http.service";


export const vendorBalance=async()=>{
    try {
        const result= await http.get(API_URL+'nawec-vendor-balance');
        return result;
    } catch (error) {
        return error;
    }
}


export const cashPowerDailySale=async()=>{
    try {
        const result= await http.get(API_URL+'CashPowerDailySales');
        return result;
    } catch (error) {
        return error;
    }
}

export const cashPowerMonthlySale=async()=>{
    try {
        const result= await http.get(API_URL+'CashPowerMonthlySales');
        return result;
    } catch (error) {
        return error;
    }
}

export const transactionsCount=async()=>{
    try {
        const result= await http.get(API_URL+'transactionsCount');
        return result;
    } catch (error) {
        return error;
    }
}

export const airPowerDailySale=async()=>{
    try {
        const result= await http.get(API_URL+'AirPowerDailySales');
        return result;
    } catch (error) {
        return error;
    }
}

export const airPowerMonthlySale=async()=>{
    try {
        const result= await http.get(API_URL+'AirPowerMonthlySales');
        return result;
    } catch (error) {
        return error;
    }
}

export const transactionsAirTimesCount=async()=>{
    try {
        const result= await http.get(API_URL+'transactionsAirTimesCount');
        return result;
    } catch (error) {
        return error;
    }
}