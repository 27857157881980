import React, { useEffect, useState } from "react";
import DataTables from "../../components/common/DataTables";
import AddCashPower from "../../components/forms/AddCashPower";
import { getCashierTransactions } from "../../services/cash-power.service";
import PrintButton from "../../components/common/PrintIcon";
import moment from "moment";
import { print } from "../../helpers/printDocument";
import CsvDownloadButton from "react-json-to-csv";
import { filterArrayByChar } from "../../helpers/filterArrayByChar";

import { getUserId } from "../../helpers/getUserId";



const CashPower = () => {
    const [transactions, setTransactions] = useState([]);
    const [tempTransactions, setTempTransactions] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    let timeout;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    useEffect(() => {
        getCashPowerData();
    }, []);

    useEffect(() => {
        timeout = setTimeout(() => {
            filterSearch();
        }, 400);
        return () => clearTimeout(timeout);
    }, [search]);

    const filterSearch = () => {
        let records = [...tempTransactions];
        if (records.length) {
            if (search.trim()) {
                let items = filterArrayByChar(records, search);
                setTransactions(items);
            } else {
                setTransactions(records);
            }
        }
    };

    // const filterSearch = () => {
    //   let records = [...tempTransactions];
    //   if (records.length) {
    //     if (search) {
    //       let items = filterArrayByChar(records, search);
    //       setTransactions(items);
    //     } else {
    //       setTransactions(records);
    //     }
    //   }
    // };

    const getCashPowerData = async () => {
        setLoading(true);
        try {
            const user_id = getUserId();
            console.log(user_id, "cashier_id")
            const result = await getCashierTransactions(user_id);
            if (result.status === 200) {
                setTransactions(result?.data?.data || []);
                setTempTransactions(result?.data?.data || []);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const printRow = (row) => {

        const token = row.token;
        // Add dashes at the specified intervals
        const formattedToken = `${token.slice(0, 4)}-${token.slice(4, 8)}-${token.slice(8, 12)}-${token.slice(12, 16)}-${token.slice(16, 20)}`;

        let content = `<div style="width: 645px; margin: auto 5px; font-size: 18px; padding: 10px;">
            <div>
                <p style="text-align: center;">Flex Power - Nawec Cash Power</p><br>
                <p><b style="margin-right:20px">Date: </b><span>${moment(row?.created_at).format('MMM DD, YYYY')}</span></p>
                <p><b style="margin-right:20px">Receipt No:</b><span>${row?.receipt_number}</span></p>
                <p><b style="margin-right:20px">Meter No:</b><span>${row.meter_number}</span></p>
                <p><b style="margin-right:20px">Customer Name:</b><span>${row.customer_name}</span></p>
            </div>
            <div style="text-align:center;font-weight:bold;font-size:24px;">
                <p><b>Token:</b> ${formattedToken} </p>
            </div>
            <div>
                
                <p><b>Phone : </b><span>${row.telephone}</span></p>
                <p><b>Units: </b><span>${row.unit_watt} KWH</span></p>
                <p><b>Amout: </b><span>GMD ${row.vend_amount}</span></p>
                <p><b>Fee: </b><span>GMD ${row.fee}</span></p>
                <p><b>Concept: </b><span>${row.concept}</span></p>
                <p><b>Tariff Description: </b><span>${row.tariff_description}</span></p>
                <p><b>Account Balance: </b><span>${row.account_balance}</span></p>
                <p><b>Cashier: </b><span>${row.recorded_by}</span></p>
            </div>
        </div> `;
        print(content);
    };

    const columns = [
        {
            name: "Action",
            selector: (row) => row.title,
            sortable: true,
            reorder: true,
            cell: (row) => <PrintButton row={row} print={printRow} />,
        },
        {
            name: "Date",
            selector: (row) => row.created_at,
            sortable: true,
            reorder: true,
            style: {
                backgroundColor: "#F4F3F3",
            },
            format: (v) => {
                const dateStr = v.created_at; // Access the correct property
                const formattedDate = moment(dateStr).format('MMM DD, YYYY');
                return formattedDate;
            },
        },
        {
            name: "Customer Name",
            selector: (row) => row.customer_name,
            sortable: true,
            reorder: true,
        },
        {
            name: "Meter Number",
            selector: (row) => row.meter_number,
            sortable: true,
            reorder: true,
        },
        {
            name: "Tendered Amount",
            selector: (row) => row.amount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Vend Amount",
            selector: (row) => row.vend_amount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Fee",
            selector: (row) => row.fee,
            sortable: true,
            reorder: true,
        },
        {
            name: "Token",
            selector: (row) => {
                const token = row.token;
                const formattedToken = `${token.slice(0, 4)}-${token.slice(4, 8)}-${token.slice(8, 12)}-${token.slice(12, 16)}-${token.slice(16, 20)}`;
                return formattedToken;
            },
            sortable: true,
            reorder: true,
            style: {
                backgroundColor: "#F4F3F3",
            },
        },

        {
            name: "Cashier",
            selector: (row) => row.recorded_by,
            sortable: true,
            reorder: true,
        },
        {
            name: "Location",
            selector: (row) => row.location,
            sortable: true,
            reorder: true,
        },
        {
            name: "Transaction ID",
            selector: (row) => row.transaction_id,
            sortable: true,
            reorder: true,
        },
    ];

    return (
        <div className="py-3">
            <div className="row my-4">
                <div className="col-md-6 col-sm-12 mb-2">
                    <div className="p-4 d-flex justify-content-between bg-white br-20">
                        <h4 className="text-indigo">New Transactions</h4>
                        <span
                            className="bg-indigo px-5 py-2 text-white cursor-pointer"
                            onClick={handleShow}
                        >
                            Click Here
                        </span>
                    </div>
                </div>
                {/* <div className="col-md-6 col-sm-12 mb-2">
                    <div className="p-4 bg-white br-20">
                        <h4 className="text-indigo">Cash Power Balance: GMD443</h4>
                    </div>
                </div> */}
            </div>

            <div className="br-20 bg-white p-4">
                <div className="d-flex justify-content-between">
                    <h3 className="text-light-blue">All Cash Power transaction</h3>
                    <div className="search-input">
                        <div className="bg-white admin-search p-2 input-group br-20">
                            <span className="input-group-text border-0">
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="5.57617"
                                        cy="5.05273"
                                        r="4.3"
                                        stroke="#23AEE6"
                                        strokeWidth="1.4"
                                    />
                                    <line
                                        x1="10.5862"
                                        y1="11.0527"
                                        x2="8.57617"
                                        y2="9.04268"
                                        stroke="#23AEE6"
                                        strokeWidth="1.4"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </span>

                            <input
                                type="text"
                                className="form-control border-0 bg-light p-2"
                                placeholder="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search ?
                                <span onClick={() => setSearch('')} className="position-absolute fs-5 cursor-pointer z-10" style={{ top: 13, right: 60 }}>
                                    <i className="bi bi-x"></i>
                                </span> : ''}
                            <div className="ms-2 mt-1">
                                <CsvDownloadButton
                                    filename="Cash Power"
                                    data={transactions}
                                    style={{ background: "transparent", border: "none" }}
                                >
                                    <svg
                                        className="cursor-pointer"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
                                            fill="black"
                                        />
                                    </svg>{" "}
                                </CsvDownloadButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DataTables data={transactions} columns={columns} loading={loading} />
                </div>
            </div>

            <AddCashPower show={show} handleClose={handleClose} refreshData={getCashPowerData} />
        </div>
    );
};

export default CashPower;
