import React from "react";
import flight from "../../assets/img/flight.png";
import g1 from "../../assets/img/Group.png";
import g2 from "../../assets/img/Group1.png";
import g3 from "../../assets/img/Group2.png";

const WhyChooseUs = () => {
  return (
    <div className="p-5 why-choose-us">
      <div className="col-md-8 col-sm-12 mx-auto py-5">
        <div className="text-center">
          <div className=" position-relative">
            <h1>
              Why Choose <span className="text-orange">Us</span>
            </h1>
            <img
              src={flight}
              alt=""
              className="position-absolute top-0 end-0 display-none"
            />
          </div>

          <p className="section-p mx-3">
            We ensure that you’ll embark on a perfectly planned, safe vacation
            at a price you can afford.
          </p>
        </div>

        <div className="row mt-5 ">
          <div className="col-md-4 col-sm-12 mb-4">
            <div className=" bg-white rounded p-4">
              <img src={g1} alt="" className="mb-2" />
              <h4 className="my-3">24/7 Service Availability</h4>
              <p>
                Completes all the work associated with planning and processing
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4 ">
            <div className=" bg-white rounded p-4">
              <img src={g2} alt="" className="mb-2" />
              <h4 className="my-3">Cash Power Vending</h4>
              <p>
                After successful access then book from exclusive deals & pricing
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4 ">
            <div className=" bg-white rounded p-4">
              <img src={g3} alt="" className="mb-2" />
              <h4 className="my-3">Printing & Photocopy</h4>
              <p>
                Start and explore a wide range of exciting travel experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
