// import { API_URL, http } from "./http.service";


// export const login=async(payload)=>{
//     try {
//         let result= await http.post(API_URL+'login', payload)
//         return result;
//     } catch (error) {
//         return error;
//     }
// }

// export const isLogin= ()=>{
//     let token = localStorage.getItem('token');
//     return token? true : false;
// }



import { toast } from "react-toastify";
import { API_URL, http } from "./http.service";

export const login = async (payload) => {
    try {
        const response = await http.post(`${API_URL}login`, payload);

        if (response.status === 200) {
            if (response.data && response.data.status === "Ok") {
                localStorage.setItem('token', response.data.token);

                document.cookie = `user_id=${response.data.data.id}`;
                document.cookie = `user_type=${response.data.data.user_type}`;
                document.cookie = `location=${response.data.data.location}`;
                document.cookie = `username=${response.data.data.name}`;
                window.location.href = "/admin/dashboard";
                toast.success("Login Successfully");
                return { success: true };
            } else {
                console.error("Login failed:", response.data.message);
                toast.error(response.data.message);
                return { error: response.data.message };
            }
        } else {
            console.error("Login failed with status:", response.status);
            return { error: "Login failed. Please try again later." };
        }
    } catch (error) {
        console.error("Login error:", error.message);
        toast.error("Login failed. Please try again later.");
        return { error: "Login failed. Please try again later." };
    }
};

export const isLogin = () => {
    let token = localStorage.getItem('token');
    return token ? true : false;
};
