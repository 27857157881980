import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { getCookie } from '../../helpers/getCookie';
import { toast } from 'react-toastify';
import { addNewCredit, userList } from '../../services/credits.service';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer } from 'react-toastify';

const AddCreditForm = ({ show, handleClose, refreshCredits }) => {
  const userId = getCookie('user_id');
  const [user, setUsers] = useState([])
  const initialValues = {
    agent: '',
    type: '',
    amount: '',
    bank_transaction_ref: ''
  };

  const validationSchema = Yup.object({
    agent: Yup.string().required('Agent is required'),
    type: Yup.string().required('Type is required'),
    amount: Yup.number()
      .min(0, 'Amount cannot be negative')
      .required('Amount is required')
      .transform((value) => (value === '' ? 0 : value)), // Transform empty value to 0
    bank_transaction_ref: Yup.string()
      .matches(/[0-9]{3}[CHDP]{4}[0-9]{9}/, 'Invalid bank transaction ref format')
      .required('Bank Transaction Ref is required')
  });


  useEffect(() => {
    getCreditsData();
  }, []);


  const getCreditsData = async () => {
    try {
      const result = await userList();
      // console.log(result.data.data, "resultcreditagent");
      if (result.status === 200) {
        setUsers(result?.data?.data || []);
      }
    } catch (error) {
      console.error("Failed to fetch credits", error);
    }
  };


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const sanitizedForm = {
      ...values,
      amount: Math.max(values.amount, 0),
      type: values.type === 'Newec' ? 0 : values.type === 'Airtime' ? 1 : values.type,
      user_id: values.agent === 'Abc' ? 1 : values.agent === 'Test' ? 2 : values.agent,
      created_by: userId
    };

    try {
      const result = await addNewCredit(sanitizedForm);
      //console.log(result);
      if (result.status === 200) {
        resetForm();
        handleClose();
        refreshCredits();
        toast.success("Credit added successfully");
      }
    } catch (error) {
      toast.error("Error adding credit");
    } finally {
      setSubmitting(false);
    }
  };



  return (
    <>
      <ToastContainer />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-indigo'>
            Add New Credit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <FormikForm noValidate>
                <Form.Group className="mb-3" controlId="formAgent">
                  <Form.Label>Agent</Form.Label>
                  <Field as="select" name="agent" id="formAgent" className="form-select">
                    <option value="">Select Agent</option>
                    {user.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="agent" component="div" className="text-danger" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formType">
                  <Form.Label>Type</Form.Label>
                  <Field as="select" name="type" className="form-select">
                    <option value="">Select Type</option>
                    <option value="Newec">Newec</option>
                    {/* <option value="Airtime">Airtime</option> */}
                  </Field>
                  <ErrorMessage name="type" component="div" className="text-danger" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>Amount</Form.Label>
                  <Field
                    name="amount"
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                  />
                  <ErrorMessage name="amount" component="div" className="text-danger" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBankTransactionRef">
                  <Form.Label>Bank Transfer Ref</Form.Label>
                  <Field
                    name="bank_transaction_ref"
                    type="text"
                    className="form-control"
                    placeholder="Bank Transfer Ref"
                  />
                  <ErrorMessage name="bank_transaction_ref" component="div" className="text-danger" />
                </Form.Group>

                <Modal.Footer>
                  <Button variant="success" type="submit" disabled={isSubmitting}>
                    OK
                  </Button>
                  <Button variant="danger" onClick={handleClose}>
                    CANCEL
                  </Button>
                </Modal.Footer>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCreditForm;
