import { API_URL, http } from "./http.service";

export const getCashPowerCharges = () => {
    try {
        return http.get(API_URL + 'charges/list');
    } catch (error) {
        return error;
    }
}

export const addCashPowerCharge = (payload) => {
    try {
        return http.post(API_URL + 'addCashPowerCharges', payload);
    } catch (error) {
        return error;
    }
}

export const updateCashPowerChargeById = (id, payload) => {
    try {
        return http.post(API_URL + 'charges/update/' + id, payload);
    } catch (error) {
        return error;
    }
}

export const deleteCashPowerChargeById = (id) => {
    try {
        return http.post(API_URL + 'charges/delete/' + id);
    } catch (error) {
        return error;
    }
}