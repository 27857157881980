import { API_URL, http } from "./http.service";

export const getTransactions = () => {

    try {
        return http.get(API_URL + 'transactions');
    } catch (error) {
        return error;
    }
}


export const getCashierTransactions = (user_id) => {
    try {
        return http.get(`${API_URL}gettransactions/${user_id}`);
    } catch (error) {
        return error;
    }
}


export const verifyTransaction = (payload) => {
    try {
        return http.post(API_URL + 'verify', payload);
    } catch (error) { 
        return error;
    }
}

export const addNewTransaction = (payload) => {
    try {
        return http.post(API_URL + 'transactions', payload);
    } catch (error) {
        return error;
    }
}