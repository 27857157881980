// export const filterArrayByChar=(arr, search)=>{
//     return arr.filter((obj)=>{
//         if(Object.values(obj)?.includes(search)) return true;
//         else return false;
//     });
// }


export const filterArrayByChar = (array, searchTerm) => {
    const lowercasedTerm = searchTerm.toLowerCase();
    return array.filter(item => {
        // Example: Check if any value in the item object contains the searchTerm
        return Object.values(item).some(value =>
            String(value).toLowerCase().includes(lowercasedTerm)
        );
    });
};
