import React, { useState } from "react";
import avatar from '../../assets/img/Avatar.png';
import { useLocation } from 'react-router-dom';
import { constants } from "../../constants/constants";
import { getCookie } from '../../helpers/getCookie';

const AdminHeader = ({ toggle }) => {
  const location = useLocation();
  const routes = constants.routes;
  const user_type = getCookie('user_type');


  return (
    <div className="topheader">

      <div className="d-flex justify-content-end" style={{ background: "blue", padding: "10px 0 3px 3px", margin: " 0px -12px" }} >
        {/* <p className="text-white ">Online Users (67) </p> */}
        <p className="text-white mx-5"> Welcome Flex Power<strong>( {user_type} )</strong> </p>
      </div>

      <div className="marquee-text"  >
        <div class="marquee">
          <p className="text-white">
            Flexpay - your domestic money transfer. Also, you can vend mobile topup (Qcell & Africell).
          </p>
        </div>
      </div>
      <div className="" style={{ background: "#e8e8e8", padding: "3px 0px", margin: " 0px -12px" }}>
        <h4 className="text-indigo mt-2 mx-2">{routes[location.pathname]}</h4>
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center  admin-header mt-3">
        <div className="d-flex align-items-center">
          <span className="btn btn-default side-toggle-icon" onClick={toggle} type="button" >
            <i className="bi bi-list"></i>
          </span>

        </div>
        <div>
          {/* <div className="bg-white admin-search p-2 d-flex rounded-pill">
            <div className="input-group ">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="search"
              />
            </div>
            <div className="d-flex align-items-center">
              <svg className="mx-2"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_119_53)">
                  <path
                    d="M4.91992 13.7715H10.9199C11.4699 13.7715 11.9199 13.3215 11.9199 12.7715V4.77148C11.9199 4.22148 11.4699 3.77148 10.9199 3.77148H4.91992C4.36992 3.77148 3.91992 4.22148 3.91992 4.77148V12.7715C3.91992 13.3215 4.36992 13.7715 4.91992 13.7715ZM4.91992 21.7715H10.9199C11.4699 21.7715 11.9199 21.3215 11.9199 20.7715V16.7715C11.9199 16.2215 11.4699 15.7715 10.9199 15.7715H4.91992C4.36992 15.7715 3.91992 16.2215 3.91992 16.7715V20.7715C3.91992 21.3215 4.36992 21.7715 4.91992 21.7715ZM14.9199 21.7715H20.9199C21.4699 21.7715 21.9199 21.3215 21.9199 20.7715V12.7715C21.9199 12.2215 21.4699 11.7715 20.9199 11.7715H14.9199C14.3699 11.7715 13.9199 12.2215 13.9199 12.7715V20.7715C13.9199 21.3215 14.3699 21.7715 14.9199 21.7715ZM13.9199 4.77148V8.77148C13.9199 9.32148 14.3699 9.77148 14.9199 9.77148H20.9199C21.4699 9.77148 21.9199 9.32148 21.9199 8.77148V4.77148C21.9199 4.22148 21.4699 3.77148 20.9199 3.77148H14.9199C14.3699 3.77148 13.9199 4.22148 13.9199 4.77148Z"
                    fill="#A3AED0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_119_53">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.919922 0.771484)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <img src={avatar} alt="avatar" />
            </div>
          </div> */}
        </div>
      </div>
    </div >
  );
};

export default AdminHeader;
