import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { addCashPowerCharge, updateCashPowerChargeById } from "../../services/cash-power-charges.service";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const AddCashPowerCharges = ({ show, handleClose, selectedCash, getCashPowerChargesData }) => {
  const [initialValues, setInitialValues] = useState({
    start_amount: "",
    end_amount: "",
    commision_amount: "",
    amount_type: "fixed",
    status: "1",
  });

  useEffect(() => {
    if (selectedCash) {
      setInitialValues({
        start_amount: selectedCash.start_amount || "",
        end_amount: selectedCash.end_amount || "",
        commision_amount: selectedCash.commision_amount || "",
        amount_type: selectedCash.amount_type || "fixed",
        status: selectedCash.status || "1",
      });
    } else {
      setInitialValues({
        start_amount: "",
        end_amount: "",
        commision_amount: "",
        amount_type: "fixed",
        status: "1",
      });
    }
  }, [selectedCash]);

  const validationSchema = Yup.object().shape({
    start_amount: Yup.number()
      .required("Start amount is required")
      .typeError("Start amount must be a number"),
    end_amount: Yup.number()
      .required("End amount is required")
      .typeError("End amount must be a number")
      .test(
        "is-greater",
        "End amount must be greater than start amount",
        function (value) {
          const { start_amount } = this.parent;
          return value > start_amount;
        }
      ),
    commision_amount: Yup.number()
      .required("Commission amount is required")
      .typeError("Commission amount must be a number"),
    amount_type: Yup.string().required("Amount type is required"),
    status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const caseData = { ...values };

      const result = selectedCash?.id
        ? await updateCashPowerChargeById(selectedCash.id, caseData)
        : await addCashPowerCharge(caseData);

      if (result.status === 200) {
        toast.success(selectedCash?.id ? "Cash power charges updated successfully" : "Cash power charges created successfully");
        getCashPowerChargesData();
        handleClose();
        resetForm();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error("Submit Error:", error);
      toast.error("An error occurred. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCash ? "Update" : "Add"} Cash Power Charges</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="start_amount">
                      <Form.Label>Start Amount</Form.Label>
                      <Form.Control
                        name="start_amount"
                        value={values.start_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Start Amount"
                        isInvalid={touched.start_amount && errors.start_amount}
                        min="0"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_amount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="end_amount">
                      <Form.Label>End Amount</Form.Label>
                      <Form.Control
                        name="end_amount"
                        value={values.end_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="End Amount"
                        isInvalid={touched.end_amount && errors.end_amount}
                        min="0"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.end_amount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Group className="mb-3" controlId="commision_amount">
                      <Form.Label>Commission Amount OR Percentage</Form.Label>
                      <Form.Control
                        name="commision_amount"
                        value={values.commision_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Commission Amount"
                        isInvalid={touched.commision_amount && errors.commision_amount}
                        min="0"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.commision_amount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="amount_type">
                      <Form.Label>Amount Type</Form.Label>
                      <Form.Select
                        value={values.amount_type}
                        name="amount_type"
                        onChange={handleChange}
                        aria-label="Amount Type"
                      >
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        value={values.status}
                        name="status"
                        onChange={handleChange}
                        aria-label="Status"
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {selectedCash ? "Update" : "Add"} Cash Power Charges
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddCashPowerCharges;
