import React, { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import { Routes, Route } from "react-router-dom";
import Wrapper from "../../components/common/Wrapper";

import { Navigate, useNavigate } from 'react-router-dom';
import { isLogin } from "../../services/auth.service";
import CashPower from "./CashPower";

import CashierHeader from "../../components/CashierHeader";
import CashierUser from "./User";

const Cashier = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    useEffect(() => {
        if (!isLogin()) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="cashier container-fluid m-0">
            <div className="row">
                <div className="d-none d-sm-block d-md-none position-absolute top-0 start-0">
                    {
                        isOpen ? <Sidebar isOpen={isOpen} toggle={toggle} /> : ''
                    }
                </div>
                <div className="cashier-side toggle-width d-none d-md-block d-lg-block col-md-2 p-0 maindivsidebar">
                    <Sidebar />
                </div>
                <div className="col-md-10 main-data" style={{ background: "#F4F7FE" }}>
                    <Wrapper>
                        <CashierHeader toggle={toggle} isOpen={isOpen} />
                        <Routes>
                            <Route path="/" index element={<Navigate to="dashboard" />} />
                            { /* <Route path="/cashier/cash-power" index element={<CashPower />} /> */}

                            <Route path="cash-power" index element={<CashPower />}></Route>
                            <Route path="user" index element={<CashierUser />}></Route>
                        </Routes>
                    </Wrapper>
                </div>
            </div>
        </div>
    );
};

export default Cashier;
