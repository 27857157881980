import React from "react";
import CsvDownloadButton from "react-json-to-csv";

const DownloadEditIcon = ({ row, edit }) => {
  return (
    <span>
      <CsvDownloadButton
        filename="Backup"
        data={[row]}
        style={{ background: "transparent", border: "none" }}
      >
        <svg
          className="cursor-pointer"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
            fill="black"
          />
        </svg>{" "}
      </CsvDownloadButton>
      <svg
        onClick={() => edit(row)}
        className="ms-3 cursor-pointer"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.28418 15.2724V11.8865L10.8002 1.39046C10.9595 1.2444 11.1356 1.13154 11.3284 1.05187C11.5212 0.972206 11.7235 0.932373 11.9354 0.932373C12.1473 0.932373 12.3531 0.972206 12.5528 1.05187C12.7525 1.13154 12.9252 1.25104 13.0707 1.41037L14.1661 2.52571C14.3254 2.67176 14.4417 2.84437 14.515 3.04354C14.5883 3.24271 14.6247 3.44187 14.6242 3.64104C14.6242 3.85348 14.5878 4.0561 14.515 4.2489C14.4423 4.44169 14.326 4.61749 14.1661 4.77629L3.67001 15.2724H0.28418ZM11.9155 4.75637L13.0308 3.64104L11.9155 2.52571L10.8002 3.64104L11.9155 4.75637Z"
          fill="black"
        />
      </svg>
    </span>
  );
};

export default DownloadEditIcon;
