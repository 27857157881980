import React from "react";

const Shimmer = ({ count }) => {
  let rows = Array(count || 5).fill("");
  return (
    <div className="w-100 shimmer">
      {rows.map((item, i) =>
        <div key={i} className="shimmer-row">

        </div>
      )}
    </div>
  );
};

export default Shimmer;
