import { API_URL, http } from "./http.service";
// Verify the endpoint URL
export const shfitEnquire = {
    submitEnquiry: async (data) => {
        const response = await fetch(API_URL + 'shitEnquiries', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    },
};

