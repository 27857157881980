// import './App.css';
// import Home from './pages/Home';
// import { Routes, Route } from 'react-router-dom';
// import Login from './pages/Login';
// import Forget from './pages/RestEmail';
// import RestPassword from './pages/RestPassword';
// import SignUp from './pages/SignUp';
// import Admin from './pages/admin/Admin';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function App() {
//   return (
//     <>
//       <Routes>
//         <Route path='' index element={<Home />} />
//         <Route path='login' element={<Login />} />
//         <Route path='signup' element={<SignUp />} />
//         <Route path='forget' element={<Forget />} />
//         <Route path='admin/*' element={<Admin />} />
//         <Route path='password/reset/:token' element={<RestPassword />} />
//       </Routes>
//       <ToastContainer />
//     </>
//   );
// }

// export default App;


import './App.css';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Forget from './pages/RestEmail';
import RestPassword from './pages/RestPassword';
import SignUp from './pages/SignUp';
import Admin from './pages/admin/Admin';
import Cashier from './pages/Cashier/Cashier';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Routes>
        <Route path='' index element={<Home />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='forget' element={<Forget />} />
        <Route path='admin/*' element={<Admin />} />
        <Route path='cashier/*' element={<Cashier />} /> {/* Add Cashier route */}
        <Route path='password/reset/:token' element={<RestPassword />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
