import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { onlyNumber } from '../../helpers/onlyNumber';
import { getCookie } from '../../helpers/getCookie';
import { toast } from 'react-toastify';
import { addNewPostPaid, verifyPostPaid } from '../../services/post-paid.service';

const AddPostPaidTransaction = ({ show, handleClose }) => {
  const [form, setForm] = useState({
    amount: '',
    account_reference: '',
    telephone: '',
    user_id: ''
  })
  const [isVerified, setIsVerified] = useState(false);
  let userId = getCookie('user_id');

  useEffect(() => {
    setForm({
      ...form,
      user_id: userId
    })
  }, [])
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value
    })
  }
  const verifyPostPaidData = async (e) => {
    try {
      e.stopPropagation();
      const result = await verifyPostPaid(form);
      if (result.status == 200) {
        setIsVerified(true);
      }
    } catch (error) {

    }
  }

  const submit = async (e) => {
    try {
      if (!isVerified) {
        toast.error('Please verify first !')
      }
      const result = await addNewPostPaid(form);
      console.log(result);
      if (result.status == 201) {
        setForm({
          amount: '',
          account_reference: '',
          telephone: '',
          user_id: userId
        })
        handleClose();
      }
    } catch (error) {

    }
  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className='text-indigo'>
          <h4>Customer:</h4>
          <h4>Balance:</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Account Reference</Form.Label>
          <Form.Control name='account_reference' value={form.account_reference} onChange={handleChange} type="text" placeholder="Account Reference" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Amount</Form.Label>
          <Form.Control name='amount' value={form.amount} onChange={handleChange} type="text" placeholder="Amount" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control name='telephone' value={form.telephone} onChange={handleChange} type="text" onKeyPress={onlyNumber} maxLength={15} placeholder="Mobile Number" />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer >
        <Button variant="warning" onClick={verifyPostPaidData}>
          VERIFY
        </Button>
        <Button variant="success" onClick={submit}>
          OK
        </Button>
        <Button variant="danger" onClick={handleClose}>
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddPostPaidTransaction;