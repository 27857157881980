import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import HeroSectionLogin from "../components/common/HeroSectionLogin";
import Footer from "../components/common/Footer";
import { isLogin, login } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { http } from "../services/http.service";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [isRemember, setIsRemember] = useState(false);

  useEffect(() => {
    if (isLogin()) {
      navigate("/admin/dashboard");
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await login(values);
      if (result && result.data && result.data.status === "Ok") {
        localStorage.setItem("token", result.token);
        http.defaults.headers["x-auth-token"] = result.token;
        // document.cookie = `user_id=${result.data.id}`;
        // document.cookie = `user_type=${result.data.user_type}`;
        // document.cookie = `location=${result.data.location}`;
        // document.cookie = `username=${result.data.name}`;
        
        toast.success("Login Successful");
        navigate("/admin/dashboard");
      } else {
        console.error("Login failed:", result.data.message);
      }
    } catch (error) {
      console.error("Login error:", error.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HeroSectionLogin />
      <div className="container p-5">
        <div className="col-md-6 col-sm-12 mx-auto mt-5 border rounded p-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <Field
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <Field
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3 form-check">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                    name="rememberMe"
                    checked={isRemember}
                    onChange={(e) => setIsRemember(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember Me
                  </label>

                  <label className="form-check-label" htmlFor="exampleCheck1" style={{ float: 'right' }}>
                  <Link to="/forget">Forget Password</Link>
                </label>

                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Logging in..." : "Login"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
