import React from "react";

const PrintIcon = ({ row, print }) => {
  return (
    <svg
      className="cursor-pointer"
      onClick={() => print(row)}
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5428 3.21117H3.78926V0.0244751H13.5428V3.21117ZM13.5428 7.59287C13.7731 7.59287 13.9662 7.51639 14.1223 7.36343C14.2783 7.21047 14.3561 7.02139 14.3556 6.7962C14.355 6.571 14.277 6.38193 14.1215 6.22897C13.966 6.076 13.7731 5.99952 13.5428 5.99952C13.3125 5.99952 13.1196 6.076 12.9641 6.22897C12.8085 6.38193 12.7305 6.571 12.73 6.7962C12.7294 7.02139 12.8075 7.21073 12.9641 7.36423C13.1207 7.51772 13.3136 7.59393 13.5428 7.59287ZM11.9172 12.7712V9.58455H5.41484V12.7712H11.9172ZM13.5428 14.3646H3.78926V11.1779H0.538086V6.39786C0.538086 5.72069 0.775151 5.15319 1.24928 4.69537C1.72341 4.23755 2.29914 4.00837 2.97646 4.00784H14.3556C15.0464 4.00784 15.6257 4.23702 16.0933 4.69537C16.5609 5.15372 16.7945 5.72122 16.7939 6.39786V11.1779H13.5428V14.3646Z"
        fill="black"
      />
    </svg>
  );
};

export default PrintIcon;
