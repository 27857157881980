import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
 
const ConfirmModal = ({show,heading,message,onHide, onSubmit}) => {
  return (
    <Modal
      show={show} 
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={onSubmit}>Yes</Button>
        <Button variant='secondary' onClick={onHide}>No</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal