import { API_URL, http } from "./http.service";

export const getCashiers=()=>{
    try {
        return http.get(API_URL+'get/cashiers');
    } catch (error) {
        return error;
    }
}

export const generateReport=(payload)=>{
    try {
        return http.post(API_URL+'generate/revenue/report',payload);
    } catch (error) {
        return error;
    }
}