import React from "react";
import Header from "./Header";

const HeroSectionLogin = () => {
  return (
    <div className="container-fluid hero-section-login">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-12 mx-auto">
            <h1 className="heading-text text-center mt-5">Login</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionLogin;
