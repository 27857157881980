import React from "react";
import Header from "./Header";

const HeroSection = () => {
  return (
    <div className="container-fluid hero-section">
      <Header />
      <div className="container">
        <div className="row heading-info ">
          <div className="col-md-8 col-sm-12 my-auto">
            <h1 className="heading-text">About Flex Power</h1>
            <p className="heading-content">
              Flex Power is a nationwide cash power vendor. We partner with NAWEC
              to bring you all your electricity needs. We've office throughout
              the country and are open all day.
            </p>
            
            <button className='btn btn-orange fs-5 p-3'>Read More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
