// export const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
// };


export const getCookie = (name) => {
    const pattern = new RegExp('(^| )' + name + '=([^;]+)');
    const match = document.cookie.match(pattern);
    return match ? decodeURIComponent(match[2]) : null;
};
