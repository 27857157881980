import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "../components/common/Footer";
import { isLogin } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { API_URL, http } from "../services/http.service";
import { toast } from "react-toastify";
import Header from "../components/common/Header";

const ResetEmail = () => {
  const navigate = useNavigate();
  const [isRemember, setIsRemember] = useState(false);

  useEffect(() => {
    if (isLogin()) {
      navigate("/login");
    }
  }, [navigate]);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await http.post(API_URL + 'password/forget', values);

      if (response.data.success) {
        resetForm();
        navigate("/login");
        toast.success(response.data.message);
      
        // Optionally, you can redirect the user to a specific page
        // navigate("/somewhere");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      toast.error("An error occurred. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="container-fluid hero-section-login">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-12 mx-auto">
              <h1 className="heading-text text-center mt-5">Reset Password</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-5">
        <div className="col-md-6 col-sm-12 mx-auto mt-5 border rounded p-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <Field
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetEmail;
