import React, { useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DataTables from "../../components/common/DataTables";
import { generateReport, getCashiers } from "../../services/reports.service";
import moment from "moment";
import CsvDownloadButton from "react-json-to-csv";

const RevenueReport = () => {
  const [report, setReport] = useState({
    totalTransaction: 0,
    totalRevenue: 0,
    totalSold: 0,
    table_data: []
  });
  const [users, setUsers] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    getRevenueReportData();
  }, []);

  const getRevenueReportData = async () => {
    try {
      const result = await getCashiers();
      if (result.status === 200) {
        setUsers(result.data?.cashiers || []);
      }
    } catch (error) {
      console.error("Error fetching cashiers:", error);
    }
  };


  const printReport = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();

    const tableRows = report.table_data.map(row => `
      <tr>
        <td>${moment(row.created_at).format('DD-MM-YYYY hh:mm')}</td>
        <td>${row.amount}</td>
        <td>${row.recorded_by}</td>
        <td>${row.location}</td>
        <td>${row.revenue}</td>
      </tr>
    `).join('');

    printWindow.document.write(`
      <html>
      <head>
        <title>Print Report</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
          .container { width: 100%; max-width: 800px; margin: auto; }
          .header { text-align: center; margin-bottom: 20px; }
          .header h1 { margin: 0; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
          th { background-color: #f2f2f2; }
          .total { text-align: right; font-weight: bold; }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>Flex Power - Nawec Cash Power</h1>
            <p>${moment().format('DD-MM-YYYY hh:mm A')}</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Tendered Amount</th>
                <th>Cashier</th>
                <th>Location</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
          <div class="total">
            <p>Total Transactions: ${report.totalTransaction}</p>
            <p>Total Amount Sold: ${report.totalSold}</p>
            <p>Total Revenue: ${report.totalRevenue}</p>
          </div>
        </div>
      </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };


  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      account: '',
      entity: ''
    },
    validationSchema: Yup.object({
      from: Yup.date().required('Start date is required'),
      to: Yup.date().required('End date is required'),
      account: Yup.string().required('Account is required'),
      entity: Yup.string().required('Entity is required')
    }),
    onSubmit: async (values) => {
      try {
        const result = await generateReport(values);
        if (result?.status === 200 && result?.data) {
          setReport(result.data);
        }
      } catch (error) {
        console.error("Error generating report:", error);
      }
    }
  });

  const columns = [
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      format: (v) => moment(v).format("DD-MM-YYYY hh:mm"),
    },
    {
      name: "Tendered Amount",
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
    },
    {
      name: "Cashier",
      selector: (row) => row.recorded_by,
      sortable: true,
      reorder: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
    },
    {
      name: "Revenue",
      selector: (row) => row.revenue,
      sortable: true,
      reorder: true,
    },
  ];

  return (
    <div className="py-3" ref={ref}>
      <div className="my-4">
        <div className="mb-2">
          <div className="p-4 bg-white br-20">
            <form onSubmit={formik.handleSubmit}>
              <div className="row p-4">
                <div className="col-md-2 col-sm-12">
                  <label htmlFor="from" className="form-label">
                    Select Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="from"
                    name="from"
                    value={formik.values.from}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.from && formik.errors.from ? (
                    <div className="text-danger">{formik.errors.from}</div>
                  ) : null}
                </div>
                <div className="col-md-2 col-sm-12">
                  <label htmlFor="to" className="form-label">
                    Select End Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="to"
                    name="to"
                    value={formik.values.to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.to && formik.errors.to ? (
                    <div className="text-danger">{formik.errors.to}</div>
                  ) : null}
                </div>
                <div className="col-md-2 col-sm-12">
                  <label htmlFor="account" className="form-label">
                    Account
                  </label>
                  <select
                    className="form-select"
                    id="account"
                    name="account"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select an account</option>
                    <option value="cashPower">Cash Power</option>
                    {/* <option value="airPower">Air Power</option> */}
                  </select>
                  {formik.touched.account && formik.errors.account ? (
                    <div className="text-danger">{formik.errors.account}</div>
                  ) : null}
                </div>
                <div className="col-md-3 col-sm-12">
                  <label htmlFor="entity" className="form-label">
                    Entity
                  </label>
                  <select
                    id="entity"
                    name="entity"
                    value={formik.values.entity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-select custom-select"
                  >
                    <option value="">Select an entity</option>
                    <option value="jimatech">FlexPower</option>
                    {/* <option value="GamSwitch">GamSwitch</option> */}
                    {users?.map((user) => (
                      <option key={user?.id} value={user?.id}>
                        {user?.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.entity && formik.errors.entity ? (
                    <div className="text-danger">{formik.errors.entity}</div>
                  ) : null}
                </div>
                <div className="col-md-3 col-sm-12 d-flex align-items-end">
                  <button type="submit" className="bg-indigo text-white px-3 py-2">
                    Generate Report
                  </button>
                </div>
                <div className="col justify-content-end d-flex align-items-end pe-2">
                  <span
                    className="bg-green-light text-white px-4 py-2"
                    onClick={printReport}
                  >
                    Print
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="br-20 bg-white p-4">
        <div className="border-bottom">
          <div className="row w-100 text-center">
            <div className="col-md-4 col-sm-12">
              <h4 className="text-light-blue">Total Transactions</h4>
              <h5 className="text-light-blue">{report.totalTransaction}</h5>
            </div>
            <div className="col-md-4 col-sm-12">
              <h4 className="text-light-blue">Total Amount Sold</h4>
              <h5 className="text-light-blue">{report.totalSold}</h5>
            </div>
            <div className="col-md-4 col-sm-12">
              <h4 className="text-light-blue">Total Revenue</h4>
              <h5 className="text-light-blue">{report.totalRevenue}</h5>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div className="search-input">
            <div className="bg-white admin-search p-2 input-group br-20">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="search"
              />
              <div className="ms-2 mt-1">
                <CsvDownloadButton
                  filename="Cash Power"
                  data={report.table_data}
                  style={{ background: "transparent", border: "none" }}
                >
                  <svg
                    className="cursor-pointer"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
                      fill="black"
                    />
                  </svg>
                </CsvDownloadButton>
              </div>
            </div>
          </div>
        </div>
        <div id="printable-table">
          <DataTables data={report.table_data} columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default RevenueReport;