import DataTable from 'react-data-table-component';
import React from 'react'
import Shimmer from './Shimmer';

const paginationComponentOptions = {
	rowsPerPageText: 'Rows per Page',
	rangeSeparatorText: 'range',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Todos',
};


const DataTables = ({ data, columns, loading }) => {
	//console.log(data, "Data4444")

	return (
		<div className='mt-3'>
			<DataTable
				columns={columns}
				data={data}
				progressPending={loading}
				progressComponent={<Shimmer count={11} />}
				pagination
				paginationComponentOptions={paginationComponentOptions}
			/>
		</div>

	)
}

export default DataTables;

