import { API_URL, http } from "./http.service";

export const getTransactionsPostPaid=()=>{
    try {
        return http.get(API_URL+'transactions-post-paid');
    } catch (error) {
        return error;
    }
}

export const verifyPostPaid=(payload)=>{
    try {
        return http.post(API_URL+'make-payment-post-paid',payload);
    } catch (error) {
        return error;
    }
}

export const addNewPostPaid=(payload)=>{
    try {
        return http.post(API_URL+'transactions-post-paid',payload);
    } catch (error) {
        return error;
    }
}