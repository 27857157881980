import React from "react";
import tree from "../../assets/img/Element (2).png";
import vector from "../../assets/img/Group 42.png";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { API_URL, http } from "../../services/http.service";

const ContactUs = () => {
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await http.post(API_URL + 'contact-us', values);

      if (response.data.success) {
        resetForm();
        toast.success(response.data.message);
        // Optionally, redirect the user to a specific page
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      toast.error("An error occurred. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <div className="position-relative">
      <div className="p-5 contact-us">
        <div className="col-md-8 col-sm-12 mx-auto py-5 padding-y rounded bg-orange-light position-relative">
          <div className="text-center position-relative">
            <h1>Contact Us</h1>
            <img
              src={tree}
              alt=""
              className="position-absolute top-0 end-0 display-none"
            />
          </div>
          <div className="row p-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="col-md-8 col-sm-12 mx-auto" style={{ zIndex: 2 }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                      <div className="input-group flex-column">
                      <div className="input-topHL d-flex">
                        <span className="input-group-text" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.8493 14.7225C15.7965 12.8748 14.1526 11.4351 12.1822 10.6351C13.1621 9.90024 13.8859 8.87565 14.2511 7.70652C14.6163 6.5374 14.6044 5.28299 14.217 4.12101C13.8297 2.95902 13.0866 1.94836 12.093 1.23218C11.0993 0.515999 9.90552 0.130615 8.68068 0.130615C7.45583 0.130615 6.26204 0.515999 5.2684 1.23218C4.27475 1.94836 3.53163 2.95902 3.1443 4.12101C2.75697 5.28299 2.74507 6.5374 3.11028 7.70652C3.47549 8.87565 4.19929 9.90024 5.17917 10.6351C3.20874 11.4351 1.56481 12.8748 0.512024 14.7225C0.44679 14.8274 0.403311 14.9444 0.384173 15.0664C0.365035 15.1884 0.370629 15.3131 0.400622 15.4329C0.430615 15.5527 0.484394 15.6653 0.558763 15.7639C0.633132 15.8626 0.726572 15.9452 0.833529 16.007C0.940485 16.0688 1.05877 16.1085 1.18136 16.1236C1.30395 16.1388 1.42834 16.1292 1.54713 16.0953C1.66592 16.0614 1.77669 16.004 1.87286 15.9265C1.96902 15.849 2.04861 15.7529 2.1069 15.644C3.49829 13.239 5.95549 11.8046 8.68068 11.8046C11.4059 11.8046 13.8631 13.2398 15.2545 15.644C15.3808 15.8471 15.5811 15.9932 15.8132 16.0512C16.0452 16.1093 16.2908 16.0749 16.4979 15.9552C16.705 15.8356 16.8575 15.64 16.9231 15.41C16.9886 15.18 16.9622 14.9334 16.8493 14.7225ZM4.68773 5.96877C4.68773 5.17904 4.92191 4.40704 5.36066 3.7504C5.79941 3.09377 6.42302 2.58198 7.15264 2.27976C7.88226 1.97755 8.68511 1.89847 9.45966 2.05254C10.2342 2.20661 10.9457 2.5869 11.5041 3.14533C12.0625 3.70375 12.4428 4.41522 12.5969 5.18978C12.751 5.96434 12.6719 6.76719 12.3697 7.4968C12.0675 8.22642 11.5557 8.85003 10.899 9.28878C10.2424 9.72753 9.47041 9.96171 8.68068 9.96171C7.62205 9.9605 6.60714 9.53942 5.85858 8.79086C5.11002 8.0423 4.68895 7.02739 4.68773 5.96877Z"
                              fill="#999999"
                            />
                          </svg>
                        </span>
                        <Field style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                          className="form-control p-3"
                          placeholder="Enter your Name"
                          name="name"
                        />
                        </div>
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                      <div className="input-group flex-column">
                        <div className="input-topHL d-flex">
                        <span className="input-group-text" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                          <svg
                            width="20"
                            height="17"
                            viewBox="0 0 20 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.16699 5.89099L8.90033 9.44099C9.25588 9.70766 9.74477 9.70766 10.1003 9.44099L14.8337 5.89099"
                              stroke="#999999"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                            <rect
                              x="1.36133"
                              y="1.30798"
                              width="17.1667"
                              height="14.5"
                              rx="4.25"
                              stroke="#999999"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </span>
                        <Field style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                          type="email"
                          className="form-control p-3"
                          placeholder="Enter your Email"
                          name="email"
                        />
                        </div>
                        <ErrorMessage name="email" component="div" className="text-danger" />


                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    <Field
                      as="textarea"
                      className="form-control"
                      rows={5}
                      placeholder="Enter your Message"
                      name="message"
                    />
                    <ErrorMessage name="message" component="div" className="text-danger" />
                  </div>

                  <div className="d-flex justify-content-center py-2">
                    <button
                      type="submit"
                      className="btn bg-white text-orange-hover px-4 fs-5"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <img
            src={vector}
            alt=""
            className="position-absolute start-0 bottom-0 vector-image"
          />
        </div>
      </div>
      <div
        style={{ backgroundColor: "#F7F8FC", height: 300, zIndex: -1 }}
        className="position-absolute bottom-0 w-100"
      ></div>
    </div>
  );
};

export default ContactUs;
