import { API_URL, http } from "./http.service";

export const customerservices = {
    submitEnquiry: async (data) => {
        const response = await fetch(API_URL + 'customerenquiries', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': "YIi4AxSZmWcYNjoMytH7XaUa8",
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },
};
