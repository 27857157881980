// // // import React from 'react';
// // // import * as Yup from 'yup';
// // // import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
// // // import { Form, Button } from 'react-bootstrap';
// // // import { toast } from 'react-toastify';
// // // import { customerservices } from '../../services/customerenquiries';
// // // import { ToastContainer } from 'react-toastify';

// // // const validationSchema = Yup.object({
// // //     meterSerial: Yup.string()
// // //         .matches(/^\d+$/, 'Must be numeric')
// // //         .min(8, 'Must be at least 8 digits')
// // //         .max(12, 'Must be at most 12 digits')
// // //         .required('Required'),
// // //     // receiptNumber: Yup.string()
// // //     //     .matches(/^\d+$/, 'Must be numeric')
// // //     //     .min(10, 'Must be at least 10 digits')
// // //     //     .max(15, 'Must be at most 15 digits')
// // //     //     .required('Required'),
// // //     // account: Yup.string()
// // //     //     .matches(/^\d+$/, 'Must be numeric')
// // //     //     .min(6, 'Must be at least 6 digits')
// // //     //     .max(10, 'Must be at most 10 digits')
// // //     //     .required('Required'),

// // //     dateFrom: Yup.date().required('Required'),
// // //     dateTo: Yup.date()
// // //         .required('Required')
// // //         .test('date-to-after-date-from', 'Date To must be greater than Date From', function (value) {
// // //             const { dateFrom } = this.parent;
// // //             return !dateFrom || !value || new Date(value) > new Date(dateFrom);
// // //         }),
// // // });

// // // const CustomerEnquiries = () => {
// // //     const handleSubmit = async (values, { resetForm }) => {
// // //         try {
// // //             const formatDate = (dateStr) => {
// // //                 const date = new Date(dateStr);
// // //                 return date.toISOString().slice(0, 19).replace('T', ' ');
// // //             };

// // //             const formattedValues = {
// // //                 ...values,
// // //                 dateFrom: formatDate(values.dateFrom),
// // //                 dateTo: formatDate(values.dateTo),
// // //             };
// // //             const response = await customerservices.submitEnquiry(formattedValues);
// // //             console.log('API response:', response);
// // //             toast.success('Enquiry submitted successfully!');
// // //             resetForm();
// // //         } catch (error) {
// // //             console.error('API error:', error);
// // //             toast.error('Failed to submit enquiry. Please try again.');
// // //         }
// // //     };

// // //     return (
// // //         <div>
// // //             <ToastContainer />
// // //             <div className='container'>
// // //                 <div className='w-80 px-5'>
// // //                     <Formik
// // //                         initialValues={{
// // //                             meterSerial: '',
// // //                             // receiptNumber: '',
// // //                             // account: '',
// // //                             dateFrom: '',
// // //                             dateTo: '',
// // //                         }}
// // //                         validationSchema={validationSchema}
// // //                         onSubmit={handleSubmit}
// // //                     >
// // //                         {() => (
// // //                             <FormikForm>
// // //                                 <Form.Group className="mb-3" controlId="meterSerial">
// // //                                     <Form.Label>Meter Serial</Form.Label>
// // //                                     <Field
// // //                                         name="meterSerial"
// // //                                         type="text"
// // //                                         className="form-control"
// // //                                     />
// // //                                     <ErrorMessage name="meterSerial" component="div" className="text-danger" />
// // //                                 </Form.Group>

// // //                                 {/* <Form.Group className="mb-3" controlId="receiptNumber">
// // //                         <Form.Label>Receipt Number</Form.Label>
// // //                         <Field
// // //                             name="receiptNumber"
// // //                             type="text"
// // //                             className="form-control"
// // //                         />
// // //                         <ErrorMessage name="receiptNumber" component="div" className="text-danger" />
// // //                     </Form.Group>
// // //                     <Form.Group className="mb-3" controlId="account">
// // //                         <Form.Label>Account</Form.Label>
// // //                         <Field
// // //                             name="account"
// // //                             type="text"
// // //                             className="form-control"
// // //                         />
// // //                         <ErrorMessage name="account" component="div" className="text-danger" />
// // //                     </Form.Group> */}
// // //                                 <Form.Group className="mb-3" controlId="dateFrom">
// // //                                     <Form.Label>Date From</Form.Label>
// // //                                     <Field
// // //                                         name="dateFrom"
// // //                                         type="datetime-local"
// // //                                         className="form-control"
// // //                                     />
// // //                                     <ErrorMessage name="dateFrom" component="div" className="text-danger" />
// // //                                 </Form.Group>
// // //                                 <Form.Group className="mb-3" controlId="dateTo">
// // //                                     <Form.Label>Date To</Form.Label>
// // //                                     <Field
// // //                                         name="dateTo"
// // //                                         type="datetime-local"
// // //                                         className="form-control"
// // //                                     />
// // //                                     <ErrorMessage name="dateTo" component="div" className="text-danger" />
// // //                                 </Form.Group>
// // //                                 <Button type="submit" variant="primary">Submit</Button>
// // //                             </FormikForm>
// // //                         )}
// // //                     </Formik>
// // //                 </div>
// // //             </div>
// // //         </div>
// // //     );
// // // };

// // // export default CustomerEnquiries;


// // import React, { useState } from 'react';
// // import * as Yup from 'yup';
// // import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
// // import { Form, Button } from 'react-bootstrap';
// // import Swal from 'sweetalert2';
// // import { customerservices } from '../../services/customerenquiries';
// // import DataTables from '../../components/common/DataTables'; // Adjust the import path as necessary

// // const validationSchema = Yup.object({
// //     meterSerial: Yup.string()
// //         .matches(/^\d+$/, 'Must be numeric')
// //         .min(8, 'Must be at least 8 digits')
// //         .max(12, 'Must be at most 12 digits')
// //         .required('Required'),

// //     dateFrom: Yup.date().required('Required'),
// //     dateTo: Yup.date()
// //         .required('Required')
// //         .test('date-to-after-date-from', 'Date To must be greater than Date From', function (value) {
// //             const { dateFrom } = this.parent;
// //             return !dateFrom || !value || new Date(value) > new Date(dateFrom);
// //         }),
// // });

// // const CustomerEnquiries = () => {
// //     const [enquiries, setEnquiries] = useState([]);

// //     const handleSubmit = async (values, { resetForm }) => {
// //         try {
// //             const formatDate = (dateStr) => {
// //                 const date = new Date(dateStr);
// //                 return date.toISOString().slice(0, 10);
// //             };

// //             const formattedValues = {
// //                 ...values,
// //                 dateFrom: formatDate(values.dateFrom),
// //                 dateTo: formatDate(values.dateTo),
// //             };

// //             const response = await customerservices.submitEnquiry(formattedValues);
// //             console.log('API response:', response);
// //             setEnquiries(response.data.map(item => ({
// //                 ...item,
// //                 dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
// //             })));

// //             Swal.fire({
// //                 toast: true,
// //                 position: 'top-end',
// //                 icon: 'success',
// //                 title: 'Enquiry submitted successfully!',
// //                 showConfirmButton: false,
// //                 timer: 3000,
// //             });

// //             resetForm();
// //         } catch (error) {
// //             console.error('API error:', error);
// //             Swal.fire({
// //                 toast: true,
// //                 position: 'top-end',
// //                 icon: 'error',
// //                 title: 'Failed to submit enquiry. Please try again.',
// //                 showConfirmButton: false,
// //                 timer: 3000,
// //             });
// //         }
// //     };

// //     const columns = [
// //         {
// //             name: "Transaction ID",
// //             selector: (row) => row.transactionId,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Meter Serial",
// //             selector: (row) => row.meterSerial,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Customer Name",
// //             selector: (row) => row.customerName,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Date Transaction",
// //             selector: (row) => row.dateTransaction,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Total Amount",
// //             selector: (row) => row.totalAmount,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Unit",
// //             selector: (row) => row.unit,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Unit Payment",
// //             selector: (row) => row.unitPayment,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Receipt",
// //             selector: (row) => row.receipt,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Account",
// //             selector: (row) => row.account,
// //             sortable: true,
// //             reorder: true,
// //         },
// //         {
// //             name: "Cashier",
// //             selector: (row) => row.nameCashier,
// //             sortable: true,
// //             reorder: true,
// //         },
// //     ];

// //     return (
// //         <div>
// //             <div className='container'>
// //                 <div className='w-80 px-5'>
// //                     <Formik
// //                         initialValues={{
// //                             meterSerial: '',
// //                             dateFrom: '',
// //                             dateTo: '',
// //                         }}
// //                         validationSchema={validationSchema}
// //                         onSubmit={handleSubmit}
// //                     >
// //                         <FormikForm>
// //                             <Form.Group controlId="meterSerial">
// //                                 <Form.Label>Meter Serial</Form.Label>
// //                                 <Field type="text" name="meterSerial" className="form-control" />
// //                                 <ErrorMessage name="meterSerial" component="div" className="text-danger" />
// //                             </Form.Group>

// //                             <Form.Group controlId="dateFrom">
// //                                 <Form.Label>Date From</Form.Label>
// //                                 <Field type="date" name="dateFrom" className="form-control" />
// //                                 <ErrorMessage name="dateFrom" component="div" className="text-danger" />
// //                             </Form.Group>

// //                             <Form.Group controlId="dateTo">
// //                                 <Form.Label>Date To</Form.Label>
// //                                 <Field type="date" name="dateTo" className="form-control" />
// //                                 <ErrorMessage name="dateTo" component="div" className="text-danger" />
// //                             </Form.Group>

// //                             <Button className='mt-4' type="submit">Submit</Button>
// //                         </FormikForm>
// //                     </Formik>
// //                 </div>
// //                 <div className='mt-5'>
// //                     <DataTables columns={columns} data={enquiries} />
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default CustomerEnquiries;
// import React, { useState } from 'react';
// import * as Yup from 'yup';
// import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
// import { Form, Button } from 'react-bootstrap';
// import Swal from 'sweetalert2';
// import { customerservices } from '../../services/customerenquiries';
// import DataTables from '../../components/common/DataTables'; // Adjust the import path as necessary

// const validationSchema = Yup.object({
//     meterSerial: Yup.string()
//         .matches(/^\d+$/, 'Must be numeric')
//         .min(8, 'Must be at least 8 digits')
//         .max(12, 'Must be at most 12 digits')
//         .required('Required'),

//     dateFrom: Yup.date().required('Required'),
//     dateTo: Yup.date()
//         .required('Required')
//         .test('date-to-after-date-from', 'Date To must be greater than Date From', function (value) {
//             const { dateFrom } = this.parent;
//             return !dateFrom || !value || new Date(value) > new Date(dateFrom);
//         }),
// });

// const CustomerEnquiries = () => {
//     const [enquiries, setEnquiries] = useState([]);

//     const handleSubmit = async (values, { resetForm }) => {
//         try {
//             const formatDate = (dateStr) => {
//                 const date = new Date(dateStr);
//                 return date.toISOString().slice(0, 10);
//             };

//             const formattedValues = {
//                 ...values,
//                 dateFrom: formatDate(values.dateFrom),
//                 dateTo: formatDate(values.dateTo),
//             };

//             const response = await customerservices.submitEnquiry(formattedValues);
//             console.log('API response:', response);
//             setEnquiries(response.data.map(item => ({
//                 ...item,
//                 dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
//             })));

//             Swal.fire({
//                 toast: true,
//                 position: 'top-end',
//                 icon: 'success',
//                 title: 'Enquiry submitted successfully!',
//                 showConfirmButton: false,
//                 timer: 3000,
//             });

//             resetForm();
//         } catch (error) {
//             console.error('API error:', error);
//             Swal.fire({
//                 toast: true,
//                 position: 'top-end',
//                 icon: 'error',
//                 title: 'Failed to submit enquiry. Please try again.',
//                 showConfirmButton: false,
//                 timer: 3000,
//             });
//         }
//     };

//     const columns = [
//         {
//             name: "Transaction ID",
//             selector: (row) => row.transactionId,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Meter Serial",
//             selector: (row) => row.meterSerial,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Customer Name",
//             selector: (row) => row.customerName,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Date Transaction",
//             selector: (row) => row.dateTransaction,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Total Amount",
//             selector: (row) => row.totalAmount,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Unit",
//             selector: (row) => row.unit,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Unit Payment",
//             selector: (row) => row.unitPayment,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Receipt",
//             selector: (row) => row.receipt,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Account",
//             selector: (row) => row.account,
//             sortable: true,
//             reorder: true,
//         },
//         {
//             name: "Cashier",
//             selector: (row) => row.nameCashier,
//             sortable: true,
//             reorder: true,
//         },
//     ];

//     return (
//         <div className='pt-5'>
//             <div className='container'>
//                 <div className='w-80 px-5'>
//                     <Formik
//                         initialValues={{
//                             meterSerial: '',
//                             dateFrom: '',
//                             dateTo: '',
//                         }}
//                         validationSchema={validationSchema}
//                         onSubmit={handleSubmit}
//                     >
//                         {({ isSubmitting }) => (
//                             <FormikForm>
//                                 <Form.Group controlId="meterSerial">
//                                     <Form.Label>Meter Serial</Form.Label>
//                                     <Field type="text" name="meterSerial" className="form-control" />
//                                     <ErrorMessage name="meterSerial" component="div" className="text-danger" />
//                                 </Form.Group>

//                                 <Form.Group controlId="dateFrom">
//                                     <Form.Label>Date From</Form.Label>
//                                     <Field type="date" name="dateFrom" className="form-control" />
//                                     <ErrorMessage name="dateFrom" component="div" className="text-danger" />
//                                 </Form.Group>

//                                 <Form.Group controlId="dateTo">
//                                     <Form.Label>Date To</Form.Label>
//                                     <Field type="date" name="dateTo" className="form-control" />
//                                     <ErrorMessage name="dateTo" component="div" className="text-danger" />
//                                 </Form.Group>

//                                 <Button className='mt-4' type="submit" disabled={isSubmitting}>
//                                     {isSubmitting ? 'Waiting...' : 'Submit'}
//                                 </Button>
//                             </FormikForm>
//                         )}
//                     </Formik>
//                 </div>
//                 <div className='mt-5'>
//                     <DataTables columns={columns} data={enquiries} />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CustomerEnquiries;
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv'; // Import CSVLink
import { customerservices } from '../../services/customerenquiries';
import DataTables from '../../components/common/DataTables';

const validationSchema = Yup.object({
    meterSerial: Yup.string()
        .matches(/^\d+$/, 'Must be numeric')
        .min(8, 'Must be at least 8 digits')
        .max(12, 'Must be at most 12 digits')
        .required('Required'),

    dateFrom: Yup.date().required('Required'),
    dateTo: Yup.date()
        .required('Required')
        .test('date-to-after-date-from', 'Date To must be greater than Date From', function (value) {
            const { dateFrom } = this.parent;
            return !dateFrom || !value || new Date(value) > new Date(dateFrom);
        }),
});

const CustomerEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const formatDate = (dateStr) => {
                const date = new Date(dateStr);
                return date.toISOString().slice(0, 10);
            };

            const formattedValues = {
                ...values,
                dateFrom: formatDate(values.dateFrom),
                dateTo: formatDate(values.dateTo),
            };

            const response = await customerservices.submitEnquiry(formattedValues);
            console.log('API response:', response);
            setEnquiries(response.data.map(item => ({
                ...item,
                dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
            })));

            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Enquiry submitted successfully!',
                showConfirmButton: false,
                timer: 3000,
            });

            resetForm();
        } catch (error) {
            console.error('API error:', error);
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Failed to submit enquiry. Please try again.',
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const columns = [
        {
            name: "Transaction ID",
            selector: (row) => row.transactionId,
            sortable: true,
            reorder: true,
        },
        {
            name: "Meter Serial",
            selector: (row) => row.meterSerial,
            sortable: true,
            reorder: true,
        },
        {
            name: "Customer Name",
            selector: (row) => row.customerName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Date Transaction",
            selector: (row) => row.dateTransaction,
            sortable: true,
            reorder: true,
        },
        {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit",
            selector: (row) => row.unit,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit Payment",
            selector: (row) => row.unitPayment,
            sortable: true,
            reorder: true,
        },
        {
            name: "Receipt",
            selector: (row) => row.receipt,
            sortable: true,
            reorder: true,
        },
        {
            name: "Account",
            selector: (row) => row.account,
            sortable: true,
            reorder: true,
        },
        {
            name: "Cashier",
            selector: (row) => row.nameCashier,
            sortable: true,
            reorder: true,
        },
    ];

    // Prepare CSV data
    const csvData = enquiries.map((item) => ({
        "Transaction ID": item.transactionId,
        "Meter Serial": item.meterSerial,
        "Customer Name": item.customerName,
        "Date Transaction": item.dateTransaction,
        "Total Amount": item.totalAmount,
        "Unit": item.unit,
        "Unit Payment": item.unitPayment,
        "Receipt": item.receipt,
        "Account": item.account,
        "Cashier": item.nameCashier,
    }));

    return (
        <div>
            <div className='container'>
                <div className='w-80 px-5'>
                    <Formik
                        initialValues={{
                            meterSerial: '',
                            dateFrom: '',
                            dateTo: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <FormikForm>
                                <Form.Group controlId="meterSerial">
                                    <Form.Label>Meter Serial</Form.Label>
                                    <Field type="text" name="meterSerial" className="form-control" />
                                    <ErrorMessage name="meterSerial" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="dateFrom">
                                    <Form.Label>Date From</Form.Label>
                                    <Field type="date" name="dateFrom" className="form-control" />
                                    <ErrorMessage name="dateFrom" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="dateTo">
                                    <Form.Label>Date To</Form.Label>
                                    <Field type="date" name="dateTo" className="form-control" />
                                    <ErrorMessage name="dateTo" component="div" className="text-danger" />
                                </Form.Group>

                                <Button className='mt-4' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Waiting...' : 'Submit'}
                                </Button>
                            </FormikForm>
                        )}
                    </Formik>
                </div>

                <div className='mt-5 text-end'>
                    <Button className='mb-4' variant="primary">
                        <CSVLink data={csvData} filename="enquiries.csv" className='text-white text-decoration-none'>
                            Download CSV
                        </CSVLink>
                    </Button>

                    <DataTables columns={columns} data={enquiries} />
                </div>
            </div>
        </div>
    );
};

export default CustomerEnquiries;
