import React, { useEffect, useState } from "react";
import DataTables from "../../components/common/DataTables";
import { getBackups } from "../../services/backup.service";
import moment from "moment";
import DownloadEditIcon from "../../components/common/DownloadEditIcon";

const Backup = () => {
  const [backups, setBackups] = useState([]);
  useEffect(()=>{
    getBackupsData();
  },[])

  const getBackupsData=async()=>{
    try {
      const result= await getBackups();
      console.log(result)
      if(result.status== 200){
        setBackups(result?.data?.data||[])
      }
    } catch (error) {
      
    }
  }
  const editRow = (row) => {

  };

  const columns = [
    {
      name: "Action",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      cell: (row) => <DownloadEditIcon row={row} edit={editRow} />,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      style: {
        backgroundColor: "#F4F3F3",
      },
      format: (v) => {
        return moment(v).format("DD-MM-YYYY hh:mm");
      },
    },
    {
      name: "File",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
    },
  ];

  return (
    <div className="py-3">
      <div className="row my-4">
        <div className="col-md-8 col-sm-12 mb-2">
          <div className="p-4 d-flex justify-content-between bg-white br-20">
            <h4 className="text-indigo">Create New Backup</h4>
            <span className="bg-indigo px-5 py-2 text-white cursor-pointer">Click Here</span>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mb-2">
          
        </div>
      </div>

      <div className="br-20 bg-white p-4">
        <div className="d-flex justify-content-between">
          <h3 className="text-light-blue">
             Backup History
          </h3>
          <div className="search-input">
            <div className="bg-white admin-search p-2 input-group br-20">
              <span className="input-group-text border-0">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5.57617"
                    cy="5.05273"
                    r="4.3"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.5862"
                    y1="11.0527"
                    x2="8.57617"
                    y2="9.04268"
                    stroke="#23AEE6"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control border-0 bg-light p-2"
                placeholder="search"
              />

              <div className="ms-2 mt-1">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.97917 13.0712L3.53625 7.62832L5.06027 6.04987L7.89058 8.88019V0.00823975H10.0677V8.88019L12.8981 6.04987L14.4221 7.62832L8.97917 13.0712ZM2.44767 17.4256C1.84895 17.4256 1.33659 17.2126 0.910594 16.7866C0.484595 16.3606 0.271234 15.8478 0.270508 15.2484V11.9826H2.44767V15.2484H15.5107V11.9826H17.6878V15.2484C17.6878 15.8471 17.4748 16.3598 17.0488 16.7866C16.6228 17.2133 16.1101 17.4263 15.5107 17.4256H2.44767Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            
          </div>
        </div>
        <div>
          <DataTables data={backups} columns={columns}  />
        </div>
      </div>
    </div>
  );
};

export default Backup;
