import React from "react";
import tree from "../../assets/img/tree.png";
import image from "../../assets/img/image.png";
import arrowup from "../../assets/img/arrow-up.png";

const OurTeam = () => {
  return (
    <div className="p-5 our-team">
      <div className="col-md-8 col-sm-12 mx-auto py-5">
        <div className="text-center">
          <div className=" position-relative">
            <h1>Our Team</h1>
            <img
              src={tree}
              alt=""
              className="position-absolute top-0 end-0 display-none"
            />
          </div>

          <p className=" section-p mx-3">
            Plan your perfect vacation with our travel agency. Choose among
            hundreds of all-inclusive offers!
          </p>
        </div>

        <div className="row mt-5 ">
          <div className="col-md-4 col-sm-4 text-center">
            <div className="bg-white rounded mb-4">
              <img
                src={image}
                alt=""
                className="pt-3 our-team-img "
              />
              <div className="card p-2 border-none  pt-3">
                <h3 className="my-1">Baboucarr Gaye.</h3>
                <p className="text-orange fw-bold">Co-founder</p>
                <p className="">
                  if you would like to get in touch with Mr. Mbye Gaye then
                  please reach out on this
                </p>
                <div>
                  <img src={arrowup} alt="" />
                </div>
                <p>Phone number</p>
                <p className="text-orange fw-bold">+44,7403 002849</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 text-center">
            <div className="bg-white mb-4 rounded">
              <img src={image} alt="" className="pt-3 our-team-img " />
              <div className="card p-2 border-none  pt-3">
                <h3 className="my-1">Baboucarr Gaye.</h3>
                <p className="text-orange fw-bold">Co-founder</p>
                <p className="">
                  if you would like to get in touch with Mr. Mbye Gaye then
                  please reach out on this
                </p>
                <div>
                  <img src={arrowup} alt="" />
                </div>
                <p>Phone number</p>
                <p className="text-orange fw-bold">+44,7403 002849</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4  mb-4 text-center">
            <div className="bg-white rounded">
              <img src={image} alt="" className="pt-3 our-team-img " />
              <div className="card p-2 border-none  pt-3">
                <h3 className="my-1">Baboucarr Gaye.</h3>
                <p className="text-orange fw-bold">Co-founder</p>
                <p className="">
                  if you would like to get in touch with Mr. Mbye Gaye then
                  please reach out on this
                </p>
                <div>
                  <img src={arrowup} alt="" />
                </div>
                <p>Phone number</p>
                <p className="text-orange fw-bold">+44,7403 002849</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
