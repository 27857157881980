import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

import { shfitEnquire } from '../../services/shiftEnquiries';
import DataTables from '../../components/common/DataTables';

const validationSchema = Yup.object({
    paymentDate: Yup.date().required('Payment date is required'),
});

const ShiftEnquiries = () => {

    const [shiftEnquiries, setShiftEnquiries] = useState([]);


    const handleSubmit = async (values, { resetForm }) => {
        const formattedValues = {
            ...values,
            paymentDate: dayjs(values.paymentDate).format('YYYY-MM-DD'),
        };

        try {
            console.log('Submitting values:', formattedValues);

            const response = await shfitEnquire.submitEnquiry(formattedValues);
            setShiftEnquiries(response.data.map(item => ({
                ...item,
                dateTransaction: new Date(item.dateTransaction).toLocaleDateString(),
            })));
            console.log('API response:', response);

            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Data submitted successfully!',
                showConfirmButton: false,
                timer: 3000,
            });

            resetForm();
        } catch (error) {
            console.error('Submit error:', error);

            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Failed to submit data. Please try again.',
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };



    const columns = [
        {
            name: " account ",
            selector: (row) => row.account,
            sortable: true,
            reorder: true,
        },
        {
            name: "Customer Name",
            selector: (row) => row.customerName,
            sortable: true,
            reorder: true,
        },
        {
            name: "Debt Payment ",
            selector: (row) => row.debtPayment,
            sortable: true,
            reorder: true,
        },
        {
            name: "Meter Serial ",
            selector: (row) => row.meterSerial,
            sortable: true,
            reorder: true,
        },
        {
            name: "Receipt",
            selector: (row) => row.receipt,
            sortable: true,
            reorder: true,
        },
        {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
            sortable: true,
            reorder: true,
        },
        {
            name: "Transaction Date",
            selector: (row) => row.transactionDate,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit",
            selector: (row) => row.unit,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit Payment",
            selector: (row) => row.unitPayment,
            sortable: true,
            reorder: true,
        },
        {
            name: "Unit Type",
            selector: (row) => row.unitType,
            sortable: true,
            reorder: true,
        },
    ];
    return (
        <div className='pt-5'>
            <div className='container'>
                <div className='w-80 px-5'>
                    <Formik
                        initialValues={{
                            paymentDate: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <FormikForm>
                                <Form.Group className="mb-3" controlId="paymentDate">
                                    <Form.Label>Payment Date</Form.Label>
                                    <Field
                                        name="paymentDate"
                                        type="date" // Changed from datetime-local to date
                                        className="form-control"
                                    />
                                    <ErrorMessage name="paymentDate" component="div" className="text-danger" />
                                </Form.Group>

                                <div className='pb-5'>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Waiting...' : 'Submit'}
                                    </Button>
                                </div>
                            </FormikForm>
                        )}
                    </Formik>
                </div>

                <div className='mt-5'>
                    <DataTables columns={columns} data={shiftEnquiries} />
                </div>

            </div>
        </div>
    );
};

export default ShiftEnquiries;
