import React from "react";
import DataTables from "../../components/common/DataTables";

const Cleanup = () => {
  return (
    <div className="py-3">
      <div className="row my-4">
        <div className="col-md-12 col-sm-12 mb-2">
          <div className="p-5 d-flex flex-wrap align-items-center bg-white br-20">
            <div className="row w-100 d-flex flex-wrap align-items-center">
              <div className="col-md-3 col-sm-12 mb-3">
                <span className=" bg-indigo px-3 py-2 text-white cursor-pointer">Cleanup Records</span>
              </div>
              <div className="text-gray col-md-3 col-sm-12 mb-3">
                <span>
                  AND KEEP ONLY THE LAST
                </span>
              </div>
              <div className="col-md-3 col-sm-12 mb-3">
                <div>
                  <label htmlFor="validationCustom01" className="form-label">
                    DAYS OF TRANSACTION
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom01"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cleanup;
