import React from "react";

const EditDeleteIcon = ({ row, edit, deleteRow }) => {
  return (
    <span>
      
      <svg
        onClick={()=>deleteRow(row)}
        className="cursor-pointer"
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.74882 12.7884C1.74882 13.6647 2.46582 14.3817 3.34215 14.3817H9.71548C10.5918 14.3817 11.3088 13.6647 11.3088 12.7884V4.82175C11.3088 3.94541 10.5918 3.22841 9.71548 3.22841H3.34215C2.46582 3.22841 1.74882 3.94541 1.74882 4.82175V12.7884ZM11.3088 0.838415H9.31715L8.75152 0.272781C8.60812 0.129381 8.40098 0.041748 8.19385 0.041748H4.86378C4.65665 0.041748 4.44952 0.129381 4.30612 0.272781L3.74048 0.838415H1.74882C1.31065 0.838415 0.952148 1.19691 0.952148 1.63508C0.952148 2.07325 1.31065 2.43175 1.74882 2.43175H11.3088C11.747 2.43175 12.1055 2.07325 12.1055 1.63508C12.1055 1.19691 11.747 0.838415 11.3088 0.838415Z"
          fill="black"
        />
      </svg>
      <svg 
       onClick={()=>edit(row)}
      className="ms-3 cursor-pointer"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.28418 15.2724V11.8865L10.8002 1.39046C10.9595 1.2444 11.1356 1.13154 11.3284 1.05187C11.5212 0.972206 11.7235 0.932373 11.9354 0.932373C12.1473 0.932373 12.3531 0.972206 12.5528 1.05187C12.7525 1.13154 12.9252 1.25104 13.0707 1.41037L14.1661 2.52571C14.3254 2.67176 14.4417 2.84437 14.515 3.04354C14.5883 3.24271 14.6247 3.44187 14.6242 3.64104C14.6242 3.85348 14.5878 4.0561 14.515 4.2489C14.4423 4.44169 14.326 4.61749 14.1661 4.77629L3.67001 15.2724H0.28418ZM11.9155 4.75637L13.0308 3.64104L11.9155 2.52571L10.8002 3.64104L11.9155 4.75637Z"
          fill="black"
        />
      </svg>

    </span>
  );
};

export default EditDeleteIcon;
