import { API_URL, http } from "./http.service";

export const getUsers = () => {
    try {
        return http.get(API_URL + 'register');
    } catch (error) {
        return error;
    }
}

export const getCashier = (user_id) => {
    try {
        return http.get(`${API_URL}users/getlist/${user_id}`);
    } catch (error) {
        return error;
    }
}

export const addUser = (payload) => {
    try {
        return http.post(API_URL + 'register', payload);
    } catch (error) {
        return error;
    }
}

export const updateUserById = (id, payload) => {
    try {
        return http.put(API_URL + 'users/update/' + id, payload);
        // https://api.testwpwebsite.online/api/users/update/5
    } catch (error) {
        return error;
    }
}

export const deleteUserById = (id) => {
    try {
        return http.delete(API_URL + 'users/delete/' + id);
        // https://api.testwpwebsite.online/api/users/delete/5
    } catch (error) {
        return error;
    }
}