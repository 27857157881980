import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  borderJoinStyle:'round',
  plugins: {
    legend: {
      position: 'top',
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

const labels = ['Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data:  [65, 54, 83, 81, 59, 45, 40],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      tension: 0.4
    },
    {
      label: 'Dataset 2',
      data:  [60, 59, 80, 87, 56, 55, 48],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      tension: 0.4
    },
  ],
};

const CashPowerDailySalesChart = () => {
  console.log(data)  
  return (
    <Line options={options} data={data} />
  )
}

export default CashPowerDailySalesChart